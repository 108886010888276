import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import Dropdown from "../components/atoms/dropdown";

function DesignDropdown() {
  return (
    <div className="design__content">
      <SEO title="Design System - Dropdown" />
      <h2>Dropdown</h2>

      <div className="design__stories">
        <div className="design__story-section">
          <h5>Dropdowns</h5>
          <DesignStory
            type="component"
            component={Dropdown}
            className="shared__select--spacing"
            compact
            props={{
              color: "lightEucalyptus",
              options: [1, 2, 3, 4, 5, 6],
            }}
          />
          <DesignStory
            type="component"
            component={Dropdown}
            className="shared__select--spacing"
            compact
            props={{
              color: "purple",
              options: [1, 2, 3, 4, 5, 6],
            }}
          />
          <DesignStory
            type="component"
            component={Dropdown}
            className="shared__select--spacing"
            compact
            props={{
              color: "orange",
              options: [1, 2, 3, 4, 5, 6],
              value: 2,
            }}
          />
          <DesignStory
            type="component"
            component={Dropdown}
            className="shared__select--spacing"
            compact
            props={{
              color: "yellow",
              options: [1, 2, 3, 4, 5, 6],
              value: 3,
            }}
          />
          <DesignStory
            type="component"
            component={Dropdown}
            className="shared__select--spacing"
            compact
            props={{
              color: "green",
              options: [1, 2, 3, 4, 5, 6],
              value: 4,
            }}
          />
          <DesignStory
            type="component"
            component={Dropdown}
            className="shared__select--spacing"
            compact
            props={{
              color: "blue",
              options: [1, 2, 3, 4, 5, 6],
              value: 5,
            }}
          />
        </div>

        <div className="design__story-section">
          <h5>Dropdowns with options as objects</h5>
          <DesignStory
            type="component"
            component={Dropdown}
            className="shared__select--spacing"
            compact
            props={{
              color: "lightEucalyptus",
              options: [
                { id: 1, value: "One" },
                { id: 2, value: "Two" },
                { id: 3, value: "Three" },
                { id: 4, value: "Four" },
              ],
            }}
          />
        </div>

        <div className="design__story-section">
          <DesignProps component={Dropdown} title="Dropdown Properties" />
        </div>
      </div>
    </div>
  );
}

export default DesignDropdown;

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { slugify } from "../../utils";
import useOutsideClick from "../../hooks/useOutsideClick";
import CaretIcon from "./icons/caret";

function Dropdown({
  options,
  value,
  color,
  inactive,
  onChange,
  className,
  forwardRef,
}) {
  const [activeValue, setActiveValue] = useState(value || options[0]);
  const [open, setOpen] = useState();
  const dropdownRef = useRef(forwardRef);

  useOutsideClick(dropdownRef, () => {
    setOpen(false);
  });

  let classList = ["shared__select", `shared__select--${color}`];
  const handleChange = (e, v) => {
    e.preventDefault();
    setActiveValue(v);
  };
  const getOptionValue = (option, type) => {
    if (option && typeof option === "object") {
      if (type === "display") {
        return option.value;
      }
      return option.id;
    }
    return option;
  };
  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );
  if (inactive) {
    classList.push("shared__select--inactive");
  }

  const dropdownValue = onChange ? value : activeValue;
  return (
    <div
      className={classList.join(" ")}
      onClick={() => {
        if (open) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      }}
      onKeyDown={() => setOpen(true)}
      role="button"
      tabIndex="0"
      ref={dropdownRef}>
      <div
        className={`shared__select-inner ${open ? "open" : ""}`}
        role="listbox">
        <div className="shared__select-trigger">
          <span>{getOptionValue(dropdownValue, "display")}</span>
          <CaretIcon className={`${open ? "caret--active" : ""}`} />
        </div>
        <div className="shared__select-custom-options">
          {options.map((o) => (
            <span
              key={slugify(getOptionValue(o, "display"))}
              className={`shared__select-custom-option ${
                getOptionValue(o, "id") === getOptionValue(dropdownValue, "id")
                  ? "selected"
                  : ""
              }`}
              aria-selected={
                getOptionValue(o, "id") === getOptionValue(dropdownValue, "id")
              }
              onClick={(e) => (onChange ? onChange(e, o) : handleChange(e, o))}
              onKeyPress={(e) =>
                onChange ? onChange(e, o) : handleChange(e, o)
              }
              role="option"
              tabIndex={0}
              data-value={getOptionValue(o, "id")}>
              {getOptionValue(o, "display")}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

Dropdown.designSystemProps = {
  color: {
    type: "string",
    description: "Dropdown color.",
    default: "lightEucalyptus",
    propType: PropTypes.string,
    required: false,
  },
  className: {
    type: ["string", "array"],
    description: "List of classes for this element.",
    required: false,
    default: "",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  },
  forwardRef: {
    type: ["function", "object"],
    description: "Ref to component or DOM element.",
    subProps: {
      current: {
        type: ["any"],
        description: "DOM node of Ref object",
        required: false,
        default: null,
      },
    },
    required: false,
    default: null,
    propType: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.any }),
    ]),
  },
  onChange: {
    type: "func",
    propType: PropTypes.func,
    description: "Function that executes when dropdown value changes.",
    required: false,
    default: null,
  },
  value: {
    type: ["string", "number", "object"],
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ]),
    description: "Initial value of dropdown.",
    required: false,
    default: null,
  },
  inactive: {
    type: "boolean",
    description: "Inactive if true.",
    required: false,
    propType: PropTypes.bool,
    default: false,
  },
  options: {
    type: "array",
    propType: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ]),
    ).isRequired,
    description: "Dropdown options.",
    required: true,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(Dropdown.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
Dropdown.propTypes = { ...propTypes };
Dropdown.defaultProps = { ...defaultProps };

Dropdown.displayName = "Dropdown";

export default Dropdown;
